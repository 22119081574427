import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    customers: [],
    customer: null,
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
    editCustomer: {
        isEdit: false,
        selectedOption: null,
    },
    firstConductor: {
        isEdit: false,
        selectedOption: null,
    },
    secondConductor: {
        isEdit: false,
        selectedOption: null,
    },
};
// ----------------------------------------------------------------------
export const getCustomers = createAsyncThunk('customers/getCustomers', async ({ page = 0, rowsPerPage = 5, orderBy = 'createdAt', order = 'desc', filterName = '', filterIdentity = '', selectIdentity = '', isPaging = true, }) => {
    let data;
    const queryParams = {
        search: {
            firstName: filterName,
            lastName: filterName,
            fullName: filterName,
            phone: encodeURIComponent(filterName),
            'nationality.code': filterName,
            'customerIdentification.number': filterIdentity,
            'customerIdentification.type': selectIdentity,
        },
        searchJoin: 'OR',
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/customers', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getCustomer = createAsyncThunk('customers/getCustomer', async (id) => {
    let data;
    try {
        const response = await axios.get(`/customers/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const banCustomers = createAsyncThunk('customers/banCustomers', async (Data) => {
    let data;
    const newIds = Data?.ids.map((el) => Number(el));
    const newData = { ids: newIds, description: Data.description };
    try {
        const response = await axios.put(`customers/banned`, newData);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const emailCheckByReservationId = createAsyncThunk('customers/emailCheckByReservationId', async (id) => {
    let data;
    try {
        const response = await axios.get(`customers/checkEmail/${id}`);
        data = await response.data;
        return data;
    }
    catch (err) {
        return err;
    }
});
const slice = createSlice({
    name: 'pricingGroup',
    initialState,
    reducers: {
        handleEditCustomer: (state, action) => {
            state.editCustomer = action.payload;
        },
        handleFirstConductor: (state, action) => {
            state.firstConductor = action.payload;
        },
        handleSecondConductor: (state, action) => {
            state.secondConductor = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomers.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getCustomers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.customers = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getCustomers.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getCustomer.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getCustomer.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.customer = action.payload.data;
        })
            .addCase(getCustomer.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(banCustomers.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(banCustomers.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(banCustomers.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const { handleEditCustomer, handleFirstConductor, handleSecondConductor } = slice.actions;
export const reducer = slice.reducer;
export default slice;
